<template>
  <!--contact Form-->
  <section class="contactusform-section">
    <div class="container">
      <div class="contact-info">
        <h2>Contactez <span>Nous</span></h2>
        <p>Comment peux t'on vous aider?</p>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-5">
          <div class="contactform-img">
            <img
              src="../../../assets/img/contactform-img.svg"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-7 col-md-7">
          <div class="contactus-form">
            <b-form class="">
              <div class="form-group">
                <b-form-input
                  type="text"
                  class="form-control"
                  v-model="message.name"
                  placeholder="Nom*"
                />                            
              <span v-if="v$.message.name.$error" class="text-light-danger">{{ $t(v$.message.name.$errors[0].$message) }}</span>
              </div>
              <div class="form-group me-0">
                <b-form-input
                  type="email"
                  class="form-control"
                  v-model="message.email"
                  placeholder="Email *"
                />
              <span v-if="v$.message.email.$error" class="text-light-danger">{{ $t(v$.message.email.$errors[0].$message) }}</span>
              </div>
              <div class="form-group">
                <b-form-input
                  type="text"
                  class="form-control"
                  v-model="message.subject"
                  placeholder="Sujet *"
                />
              <span v-if="v$.message.subject.$error" class="text-light-danger">{{ $t(v$.message.subject.$errors[0].$message) }}</span>
              </div>
              <div class="form-group">
                <textarea
                  rows="4"
                  class="form-control"
                  v-model="message.content"
                  placeholder="Ecrire un message *"
                ></textarea>
              <span v-if="v$.message.content.$error" class="text-light-danger">{{ $t(v$.message.content.$errors[0].$message) }}</span>
              </div>
              <div class="submit-section">
                <b-button variant="primary submit-btn" type="submit" @click="send()">
                  Envoyez
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--/contact Form-->
</template>

<script>
import GenericServices from "@/modules/generic.services.js"
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import MessageModule from '@/modules/messageModule.js'
export default {
  data() {
    return {
        v$: useVuelidate(),
        message: {
          name: '',
          email: '',
          subject: '',
          content: ''
        }
      }
  },
  validations () {
    return {
      message : {
        name: { required },
        email: { required, email },
        subject: { required },
        content: { required } 
      }
    }
  },
  methods: {
    async send() {
        this.v$.$validate() // checks all inputs
        if (!this.v$.$error) {
          try {
            await GenericServices.create('contacts/mobile/contact',this.message)
            MessageModule.setSuccess(this, 'Contact', 'Votre message a été envoyé.')
          }
          catch(err) {MessageModule.setError(this, "Erreur", err.message);}
        } 
    }
  },
};
</script>