<template>
  <div class="login-content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <div class="login-wrap">
            <div class="login-header">
              <h3>Bienvenue</h3>
              <p>Merci de vous identifier</p>
            </div>
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>

            <!-- Login Form -->
            <b-form>
              <div class="form-group group-img">
                <div class="group-img">
                  <i class="feather-mail"></i>
                  <b-form-input
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    v-model="user.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="pass-group group-img">
                  <span class="lock-icon"><i class="feather-lock"></i></span>
                  <b-form-input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="user.password"
                    placeholder="password"
                  />
                  <b-form-input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="password"
                    v-model="user.password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <label class="custom_check">
                    <input
                      type="checkbox"
                      name="rememberme"
                      class="rememberme"
                    />
                    <span class="checkmark"></span>Se souvenir de moi
                  </label>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="text-md-end">
                    <router-link class="forgot-link" to="forgot-password"
                      >Mot de passe oublié?</router-link
                    >
                  </div>
                </div>
              </div>
              <b-button
                variant="primary w-100 login-btn"
                type="submit"
                @click="login()"
                >Se connecter</b-button
              >
              <div class="register-link text-center">
                <p>
                  Pas encore de compte?
                  <router-link class="forgot-link" to="/signup"
                    >S'inscrire</router-link
                  >
                </p>
              </div>
              <SignInSocial />
            </b-form>
            <!-- /Login Form -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/models/user"
import config from "@/config.js"
import SignInSocial from './SignInSocial.vue'

export default {
  components: {
    SignInSocial
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      showPassword: false,
      message: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      this.loading = true;
      if (this.user.email && this.user.password) {
/*         if (config.defaultAuth === "firebase") {
          this.$store.dispatch("firebaseAuth/login", this.user).then(
            () => {
              this.$router.push("/profile");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        } else { */
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/profile");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      //}
    }
  },
};
</script>