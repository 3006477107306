<template>
  <!-- How It Works section -->
  <section class="work-section">
    <div class="container">
      <div class="work-heading">
        <h4>Booster la motivation de vos équipes!</h4>
        <p class="description">
          Vos clients scannent votre QR code et récompensent le serveur de leur choix en quelques secondes !
        </p>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 d-flex">
          <div class="work-info card">
            <img
              src="../../../assets/img/works/step1.png"
              class="img-fluid"
              alt=""
            />
            <h5>01</h5>
            <h6>Créer un compte</h6>
            <p>
              Créer un compte Etablissement. <br>
              Associer vos collaborateurs à votre établissement.<br> 
              Mettez à disposition le QR Code de votre établissement ou laisser faire Tiiips!
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 d-flex">
          <div class="work-info card">
            <h5 class="mt-0">02</h5>
            <h6>Le QR code est scanné</h6>
            <p>
              Vos clients découvrent la page de votre restaurant où ils retrouveront tous les membres de votre équipe.<br>
              <ul>
                <li>- Sélectionner son serveur</li>
                <li>- Choisir le montant du pourboire dès 1€</li>
                <li>- Renseigner ses coordonnées bancaires</li>
                <li>- Valider</li>
              </ul>
            </p>
            <img
              src="../../../assets/img/works/step2.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 d-flex">
          <div class="work-info card">
            <img
              src="../../../assets/img/works/step3.png"
              class="img-fluid"
              alt=""
            />
            <h5>03</h5>
            <h6>Le serveur recoit son pourboire instantanément</h6>
            <p>
              Le serveur reçoit instantanément le pourboire sur son compte Tiiips ou le wallet de son application et peut à tout moment virer l’argent sur son compte bancaire.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /How It Works section -->
</template>