<template>
  <div class="login-or">
    <span class="or-line"></span>
    <span class="span-or">Se connecter avec un compte social</span>
  </div>
  <div class="social-login">
    <a class="btn btn-apple w-100" v-on:click="loginWithApple"
      ><img src="../../../assets/img/apple.svg" class="me-1" alt="img" />Se
      connecter avec Apple</a
    >
  </div>
  <div class="social-login">
    <a class="btn btn-google w-100" v-on:click="loginWithGoogle"
      ><img src="../../../assets/img/google.svg" class="me-1" alt="img" />Se
      connecter avec Google</a
    >
  </div>
  <div class="social-login">
    <a
      href="#"
      class="btn btn-facebook w-100 mb-0"
      v-on:click="loginWithFacebook"
      ><img src="../../../assets/img/facebook.svg" class="me-2" alt="img" />Se
      connecter avec Facebook</a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    loginWithGoogle() {
      this.loading = true;
      this.$store.dispatch("firebaseAuth/loginWithGoogle", this.user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    loginWithApple() {
      this.loading = true;
      this.$store.dispatch("firebaseAuth/loginWithApple", this.user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    loginWithFacebook() {
      this.loading = true;
      this.$store.dispatch("firebaseAuth/loginWithFacebook", this.user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
};
</script>