import { createStore } from 'vuex'
import {auth} from "./auth.module.js"
import {firebaseAuth} from "./firebaseAuth.module.js"

export default new createStore({ 
    state: {
        layout: 'app'
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        },
    },
    actions: {},
    modules: {auth,firebaseAuth},
});
