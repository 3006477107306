import router from './router'

function errorHandler (err) {
  if (err === undefined) {
    router.push('/error')
  } else {
    switch (err.code) {
      case 400:
        router.push('/error-500')
        break
      case 401:
        router.push('/error-500')
        break
      case 403:
        router.push('/error-403')
        break
      case 404:
        router.push('/error-404')
        break
      case 500:
        router.push('/error-500')
        break
      default:
       // router.push('/error?code=500&message=' + err.message)
    }
  }
}

export default errorHandler