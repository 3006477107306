
export const defaultLocale = 'fr'
export const localeOptions = [
  { id: 'en', name: 'English' },
  { id: 'fr', name: 'Français' }
]
export const getCurrentLanguage = () => {
  let locale = defaultLocale
  try {
    if (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) { locale = localStorage.getItem('currentLanguage') }
  } catch (error) {
    locale = defaultLocale
  }
  return locale
}