<template>
  <!-- Login Section -->
  <div class="login-content">
    <div class="container">
      <div class="row">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
        <b-form v-if="step === 1">
          <div class="col-md-6 col-lg-5 mx-auto">
            <div class="login-wrap register-form">
              <div class="login-header">
                <h3>Créer un compte</h3>
                <p>Prêt à débuter l'expérience?</p>
              </div>
              <div class="form-group group-img">
                <div class="group-img">
                  <b-button
                    variant="primary w-100"
                    type="submit"
                    @click="setChoice(1)"
                    >Créer un compte établissement</b-button
                  >
                </div>
              </div>
              <div class="form-group group-img">
                <div class="group-img">
                  <b-button
                    variant="primary w-100"
                    type="submit"
                    @click="setChoice(2)"
                    >Créer un compte salarié</b-button
                  >
                </div>
              </div>
              <div class="form-group group-img">
                <div class="group-img">
                  <b-button
                    variant="primary w-100"
                    type="submit"
                    @click="setChoice(3)"
                    >Créer un compte donateur</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <!-- Login Form for establishment-->
        <b-form action="/login" v-if="step === 2 && user.role === 2">
          <div class="mx-auto">
            <div class="register-form">
              <div class="login-header">
                <h3>Créer un compte</h3>
                <p>Prêt à inscrire votre établissement ?</p>
              </div>
              <div class="profile-form">
                <b-form>
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label class="col-form-label"
                          >Prénom<span>*</span></label
                        >
                        <div class="pass-group group-img">
                          <span class="lock-icon"
                            ><i class="feather-user"></i
                          ></span>
                          <b-form-input
                            type="tel"
                            class="form-control"
                            placeholder="John"
                            v-model="establishment.firstName"
                          />
                        </div>
                        <span
                          v-if="v$.establishment.firstName.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.firstName.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label class="col-form-label">Nom<span>*</span></label>
                        <div class="group-img">
                          <i class="feather-user"></i>
                          <b-form-input
                            type="text"
                            class="form-control"
                            placeholder="Doe"
                            v-model="establishment.lastName"
                          />
                        </div>
                        <span
                          v-if="v$.establishment.lastName.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.lastName.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label class="col-form-label"
                          >Téléphone<span>*</span></label
                        >
                        <div class="pass-group group-img">
                          <span class="lock-icon"
                            ><i class="feather-phone-call"></i
                          ></span>
                          <b-form-input
                            type="tel"
                            class="form-control"
                            placeholder="+33 12 34 56 78"
                            v-model="establishment.phone"
                          />
                        </div>
                        <span
                          v-if="v$.establishment.phone.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.phone.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <label class="col-form-label"
                          >Email<span>*</span></label
                        >
                        <div class="group-img">
                          <i class="feather-mail"></i>
                          <b-form-input
                            type="email"
                            class="form-control"
                            placeholder="johndoe@email.com"
                            v-model="establishment.email"
                          />
                        </div>
                        <span
                          v-if="v$.establishment.email.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.email.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="col-form-label">Nom<span>*</span></label>
                        <div class="pass-group group-img">
                          <b-form-input
                            type="text"
                            class="form-control"
                            placeholder="Nom de l'établissement"
                            v-model="establishment.name"
                          />
                        </div>
                        <span
                          v-if="v$.establishment.name.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.name.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="col-form-label"
                          >Adresse<span>*</span></label
                        >
                        <div class="pass-group group-img">
                          <span class="lock-icon"
                            ><i class="feather-globe"></i
                          ></span>
                          <vue-google-autocomplete
                            id="map"
                            v-model="establishment.address"
                            classname="form-control"
                            placeholder="Adresse de l'établissement"
                            v-on:placechanged="getAddressData"
                          ></vue-google-autocomplete>
                        </div>
                        <span
                          v-if="v$.establishment.address.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.address.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4">
                      <div class="form-group">
                        <label class="col-form-label"
                          >Société<span>*</span></label
                        >
                        <b-form-input
                          type="text"
                          class="form-control"
                          placeholder="Mama Group"
                          v-model="establishment.group"
                        />
                        <span
                          v-if="v$.establishment.group.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.group.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="form-group">
                        <label class="col-form-label"
                          >Enseigne<span>*</span></label
                        >
                        <b-form-input
                          type="text"
                          class="form-control"
                          placeholder="Mama Pizza"
                          v-model="establishment.commercial"
                        />
                        <span
                          v-if="v$.establishment.commercial.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.commercial.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="form-group">
                        <label class="col-form-label"
                          >SIRET<span>*</span></label
                        >
                        <b-form-input
                          type="text"
                          class="form-control"
                          placeholder="xxxxx"
                          v-model="establishment.siret"
                        />
                        <span
                          v-if="v$.establishment.siret.$error"
                          class="text-light-danger"
                          >{{
                            $t(v$.establishment.siret.$errors[0].$message)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="col-form-label"
                        >{{ $t("subcription") }} <span>*</span></label
                      >
                      <b-form-select
                        class="form-control"
                        v-model="establishment.subscription"
                        :options="subscriptionChoices"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <input
                        type="checkbox"
                        id="accept"
                        class="basic_check"
                        v-model="establishment.acceptance"
                      />
                      {{ $t("acceptTerms") }}
                      <span
                        v-if="v$.establishment.acceptance.$error"
                        class="text-light-danger"
                        >{{ $t("pleaseAcceptTerms") }}</span
                      >
                    </div>
                  </div>
                </b-form>
                <b-button
                  variant="primary w-100 login-btn"
                  type="submit"
                  @click="registerEtablishment()"
                  >Créer un compte</b-button
                >
              </div>
            </div>
          </div>
        </b-form>
        <!-- Login Form for establishment-->
        <!-- Login Form for employee-->
        <b-form
          action="/login"
          v-if="step === 2 && (user.role === 5 || user.role === 10)"
        >
          <div class="col-md-6 col-lg-5 mx-auto">
            <div class="login-wrap register-form">
              <div class="login-header">
                <h3>Créer un compte</h3>
                <p>Prêt à débuter l'expérience?</p>
              </div>
              <div class="form-group group-img">
                <div class="group-img">
                  <i class="feather-user"></i>
                  <b-form-input
                    type="text"
                    class="form-control"
                    placeholder="Nom & Prénom"
                    v-model="user.fullName"
                  />
                  <span
                    v-if="v$.user.fullName.$error"
                    class="text-light-danger"
                    >{{ v$.user.fullName.$errors[0].$message }}</span
                  >
                </div>
              </div>
              <div class="form-group group-img">
                <div class="group-img">
                  <i class="feather-mail"></i>
                  <b-form-input
                    type="text"
                    class="form-control"
                    placeholder="Adresse Email"
                    v-model="user.email"
                  />
                  <span v-if="v$.user.email.$error" class="text-light-danger">{{
                    v$.user.email.$errors[0].$message
                  }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="pass-group group-img">
                  <span class="lock-icon"><i class="feather-lock"></i></span>
                  <b-form-input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="user.password"
                    placeholder="Mot de passe"
                  />
                  <b-form-input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="Mot de passe"
                    v-model="user.password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                  <span
                    v-if="v$.user.password.$error"
                    class="text-light-danger"
                    >{{ v$.user.password.$errors[0].$message }}</span
                  >
                </div>
              </div>
              <b-button
                variant="primary w-100 login-btn"
                type="submit"
                @click="register()"
                >Créer un compte</b-button
              >
              <div class="register-link text-center">
                <p>
                  Vous avez dèjà un compte?
                  <router-link class="forgot-link" to="/login"
                    >Se connecter</router-link
                  >
                </p>
              </div>
              <SignInSocial />
            </div>
          </div>
        </b-form>
        <!-- Login Form for employee-->
      </div>
    </div>
  </div>
  <!-- /Login Section -->
</template>

<script>
import User from "@/models/user";
import SignInSocial from "./../login/SignInSocial.vue";
import MessageModule from "@/modules/messageModule.js";
import { useVuelidate } from "@vuelidate/core";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { numeric, required, email, sameAs } from "@vuelidate/validators";
import Subscriptions from "./../../../assets/json/subscriptions.json";
export default {
  components: {
    SignInSocial,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      v$: useVuelidate(),
      step: 1,
      user: new User("", "", "", 10),
      subscriptionChoices: Subscriptions,
      establishment: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        name: "",
        address: {},
        group: "",
        commercial: "",
        siret: "",
        subscription: "FREE",
        acceptance: false,
      },
      title: "Profile",
      title1: "User Profile",
      showPassword: false,
      password: null,
      title01: "Profile",
      title2: "User Profile",
      showPassword2: false,
      password2: null,
      message: "",
    };
  },
  validations() {
    return {
      user: {
        fullName: { required },
        email: { required, email },
        password: { required },
      },
      establishment: {
        firstName: { required },
        lastName: { required },
        phone: { required, numeric },
        email: { required, email },
        name: { required },
        address: { required },
        group: { required },
        commercial: { required },
        siret: { required, numeric },
        acceptance: { required, sameAs: sameAs(true) },
      },
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    getAddressData(addressData /* , placeResultData, id */) {
      this.establishment.address = addressData;
    },
    setChoice(choice) {
      switch (choice) {
        case 1:
          //establishment
          this.user.role = 2;
          this.step = 2;
          break;
        case 2:
          //employee
          this.user.role = 5;
          this.step = 2;
          break;
        default:
        case 3:
          this.user.role = 10;
          this.step = 2;
          break;
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    registerEtablishment() {
      this.loading = true;
      this.v$.$validate(); // checks all inputs
      if (
        !this.v$.establishment.firstName.$invalid &&
        !this.v$.establishment.lastName.$invalid &&
        !this.v$.establishment.phone.$invalid &&
        !this.v$.establishment.email.$invalid &&
        !this.v$.establishment.name.$invalid &&
        !this.v$.establishment.address.$invalid &&
        !this.v$.establishment.group.$invalid &&
        !this.v$.establishment.commercial.$invalid &&
        !this.v$.establishment.siret.$invalid &&
        this.establishment.acceptance
      ) {
        this.$store
          .dispatch("auth/etablishementRegister", this.establishment)
          .then(
            () => {
              MessageModule.setSuccess(
                this,
                "Inscription",
                "Votre établissement est maintenant inscrit."
              );
              this.$router.push("/");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              MessageModule.setError(this, "Inscription", error.message);
            }
          );
      }
    },
    register() {
      this.loading = true;
      this.v$.$validate(); // checks all inputs
      if (
        !this.v$.user.fullName.$invalid &&
        !this.v$.user.email.$invalid &&
        !this.v$.user.password.$invalid
      ) {
        if (this.user.email && this.user.password && this.user.fullName) {
          /*    if (config.defaultAuth === "firebase") {
            this.$store.dispatch("firebaseAuth/register", this.user).then(
              () => {
                this.$router.push("/");
              },
              (error) => {
                this.loading = false;
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          } else {*/
          this.$store.dispatch("auth/register", this.user).then(
            () => {
              this.$router.push("/");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
          //    }
        }
      }
    },
  },
};
</script>