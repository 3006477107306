<template>
    <div>
        <img src="@/assets/img/no-data.svg" class="img-fluid" alt="no-data" width="250" height="250" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><br><br>
        <h6>{{title}}</h6>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NoDatas',
    props: {
      // Define label
      title: { default: 'title', type: String }
    },
    data () {
      return {
      }
    }
  }
  </script>
  