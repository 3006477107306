<template>
  <!-- Login Section -->
  <div class="login-content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <div class="login-wrap password-form">
            <div class="login-header">
              <h3>Mot de passe oublié</h3>
              <p>
                Entrez votre email afin que l'on puisse vous envoyer le lien qui vous permettra de reinitailiser votre mot de passe.
              </p>
            </div>

            <!-- Login Form -->
            <b-form action="login">
              <div class="form-group group-img">
                <div class="group-img">
                  <i class="feather-mail"></i>
                  <b-form-input
                    type="text"
                    class="form-control"
                    v-model="email"
                    placeholder="Adresse email"
                  />
                </div>
                 <span v-if="v$.email.$error" class="text-light-danger">{{ v$.email.$errors[0].$message }}</span>
              </div>
              <b-button variant="primary w-100 login-btn" type="submit" @click="send()"> Envoyer</b-button>
            </b-form>
            <router-link to="/" class="back-home"
              ><i class="fas fa-regular fa-arrow-left me-1"></i> retour à l'accueil</router-link
            >
            <!-- /Login Form -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Login Section -->
</template>

<script>

import AuthServices from "@/modules/auth.services.js"
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import MessageModule from '@/modules/messageModule.js'
export default {
  data() {
    return {
        v$: useVuelidate(),
        email: ''
    }
  },
  validations () {
    return {
      email: { required , email}
    }
  },
  methods: {
    async send() {
        this.v$.$validate() // checks all inputs
        if (!this.v$.$error) {
          try {
            await AuthServices.forgotPassword({email: this.email})
            MessageModule.setSuccess(this, 'Mot de passe oublié', 'Un email a été envoyé.')
          }
          catch(err) {MessageModule.setError(this, "Erreur", err.message);}
        } 
    }
  },
};
</script>