<template>
  <ul>
    <li class="nav-item dropdown has-arrow logged-item">
      <router-link
        to="#"
        class="dropdown-toggle pageviews-link"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>{{ $t(value) }}</span>
      </router-link>
      <div class="dropdown-menu dropdown-menu-end">
        <a class="dropdown-item" @click="changePeriod('allPeriod')">{{
          $t("allPeriod")
        }}</a>
        <a class="dropdown-item" @click="changePeriod('lastWeek')">{{
          $t("lastWeek")
        }}</a>
        <a class="dropdown-item" @click="changePeriod('lastMonth')">{{
          $t("lastMonth")
        }}</a>
        <a class="dropdown-item" @click="changePeriod('lastYear')">{{
          $t("lastYear")
        }}</a>
      </div>
    </li>
  </ul>
</template>
<script>
import moment from "moment";
moment.locale("fr");
export default {
  name: "PeriodDropDown",
  props: {
    value: { type: String, default: "allPeriod" },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  methods: {
    changePeriod(period) {
      switch (period) {
        default:
        case "allPeriod":
          this.endDate = null;
          this.startDate = null;
          break;
        case "lastYear":
          this.endDate = moment()
            .add(0, "year")
            .add(1, "days")
            .format("DD/MM/YYYY");
          this.startDate = moment().add(-1, "year").format("DD/MM/YYYY");
          break;
        case "lastMonth":
          this.endDate = moment()
            .add(0, "month")
            .add(1, "days")
            .format("DD/MM/YYYY");
          this.startDate = moment().add(-1, "month").format("DD/MM/YYYY");
          break;
        case "lastWeek":
          this.endDate = moment()
            .add(0, "week")
            .add(1, "days")
            .format("DD/MM/YYYY");
          this.startDate = moment().add(-1, "week").format("DD/MM/YYYY");
          break;
      }
      this.$emit("onChangePeriod", period, this.startDate, this.endDate);
    },
  },
};
</script>
