<template>
	<!-- FAQ Section -->
	<section class="faq-section">
		<div class="container">
			<div class="faq-info">
				<div class="page-title ">
					<h2 class="mt-0">Les avantages</h2>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqOne" aria-expanded="false">Quels
							sont les avantages pour le client ?</router-link>
					</h4>
					<div id="faqOne" class="card-collapse collapse">
						<p> Les avantages principaux sont :
						<ul class="policies">
							<li>Possibilité de donner un pourboire à la personne de son choix, rapidement et simplement</li>
							<li>Visibilité sur les pourboires donnés et les établissements fréquentés sur l’application
							</li>
							<li>Amélioration de l’expérience client</li>
						</ul>
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqTwo" aria-expanded="false">Quels
							sont les avantages pour les collaborateurs ?</router-link>
					</h4>
					<div id="faqTwo" class="card-collapse collapse">
						<p> Les avantages principaux sont :
						<ul class="policies">
							<li>Augmentation des pourboires entraine une hausse des revenus</li>
							<li>Motivation supplémentaire</li>
							<li>Contact privilégié avec le client</li>
							<li>Simplicité d’utilisation</li>
						</ul>
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqThree" aria-expanded="false">Quel
							sont les avantages pour le responsable d'établissement ?</router-link>
					</h4>
					<div id="faqThree" class="card-collapse collapse">
						<p> Les avantages principaux sont :
						<ul class="policies">
							<li>Dynamisme des équipes</li>
							<li>Preuve de considération de vos collaborateurs</li>
							<li>Augmentation des revenus de vos salariés sans contribution financière de votre part</li>
							<li>Baisse du turnover et fidélisation des collaborateurs</li>
							<li>Lien privilégié entre vos équipes et le client</li>
							<li>Proposez une solution innovante à vos clients</li>
							<li>Dynamisez l’image de votre restaurant</li>
							<li>Pourboires partagés ou personnels</li>
							<li>Gagnez du temps grâce à l’automatisation du partage des pourboires</li>
							<li>Aucune gestion nécessaire de votre part</li>
							<li>Évitez les échanges de monnaie</li>
							<li>Éviter les démarches administratives de déclaration des pourboires</li>
						</ul>
						</p>
					</div>
				</div>
				<div class="faq-card mb-0">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqFour" aria-expanded="false">Est-ce
							qu’un cuisinier, un barman, ou une personne en backoffice peut recevoir les pourboires
							?</router-link>
					</h4>
					<div id="faqFour" class="card-collapse collapse">
						<p> Oui c’est possible et très simple. Tout comme le serveur, ou tout membre de l'équipe, il lui
							suffira de se créer un compte et de renseigner son poste. Il pourra apparaitre ou non dans la
							liste des collaborateurs et recevra les pourboires en mode partagés comme le reste de l’équipe.
						</p>
					</div>
				</div>
				<div class="page-title">
					<h2>La solution</h2>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqFive" aria-expanded="false">Comment
							ça marche?</router-link>
					</h4>
					<div id="faqFive" class="card-collapse collapse">
						<p>Il suffit au client de scanner un QR code propre à l'établissement qui sera imprimé sur
							différents
							supports comme des portes additions, des chevalets de comptoirs, des affiches, …
							<br>Il arrivera ensuite sur une page internet sur laquelle il retrouvera l’ensemble de l’équipe
							avec leurs photo et leur pseudo. <br>Il lui suffira alors de sélectionner la personne à qui il
							souhaite laisser un pourboire, entrer le montant, entrer ses coordonnées bancaires et valider.
							<br>
							Le collaborateur reçoit alors instantanément le pourboire dans son compte personnel.
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqSix" aria-expanded="false">À quoi
							sert votre solution ?</router-link>
					</h4>
					<div id="faqSix" class="card-collapse collapse">
						<p>Notre solution permet à un client de donner un pourboire à un collaborateur en quelques clics sur
							son téléphone. Le but est de redémocratiser le pourboire en palliant la dématérialisation des
							moyens de paiement.</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqSeven"
							aria-expanded="false">Comment un collaborateur peut récupérer ses pourboires ?</router-link>
					</h4>
					<div id="faqSeven" class="card-collapse collapse">
						<p>Dés que le collaborateur a reçu des pourboires, il peut à tout moment faire un virement vers son
							compte bancaire, quelquesoit la somme.</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqEight" aria-expanded="false">Quelle
							est la démarche pour le responsable d'établissement ?</router-link>
					</h4>
					<div id="faqEight" class="card-collapse collapse">
						<p>Une fois l'établissement inscrit, il suffit de renseigner quelques informations, customiser la
							fiche établissement, ajouter les emails des collaborateurs.
							<br>Et c'est tout !
							<br>En fonction de votre abonnement, qui peut être totalement gratuit, vous aurez accès à des
							fonctionnalités supplémentaires.
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faqNine"
							aria-expanded="false">Quels sont les modes de distribution des pourboires ? </router-link>
					</h4>
					<div id="faqNine" class="card-collapse collapse">
						<p>Il existe actuellement 2 modes de distribution des pourboires
							<ul class="policies">
								<li>Personnel : Chaque serveur reçoit ses propres pourboires</li>
								<li>Equipe : Les pourboires sont distribués à toute l’équipe</li>
							</ul>
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq10" aria-expanded="false">Est-ce simple de mettre en place la solution ?</router-link>
					</h4>
					<div id="faq10" class="card-collapse collapse">
						<p>La solution peut être mise en place en quelques minutes. 
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq11" aria-expanded="false">Est-ce que votre solution de paiement est fiable ?</router-link>
					</h4>
					<div id="faq11" class="card-collapse collapse">
						<p>Agréé institution financière par la commission Européenne, Stripe met en place de nombreux moyens anti-fraude pour sécuriser les paiements. Toutes les transactions sont tracées.
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq12" aria-expanded="false">Les impôts comment ça se passe ?</router-link>
					</h4>
					<div id="faq12" class="card-collapse collapse">
						<p>Le restaurateur n’ayant pas la main sur la distribution des pourboires, il n’aura rien à déclarer. En effet, les pourboires sont donnés par le client au collaborateur. 
						<br>Suite à la nouvelle loi concernant la défiscalisation des pourboires en carte bancaire, les collaborateurs n'ontpas à déclarer les pourboires perçus.
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq13" aria-expanded="false">En tant que collaborateur, puis-je changer d'établissement en gardant mon compte ?</router-link>
					</h4>
					<div id="faq13" class="card-collapse collapse">
						<p>Si vous changez d'établissement, vous avez la possibilité de vous rattacher à votre nouveau lieu de travail via le panel d'administration établissement. Vous pouvez même être visible sur plusieurs établissements.
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq14" aria-expanded="false">Pourquoi cette commission ?</router-link>
					</h4>
					<div id="faq14" class="card-collapse collapse">
						<p>Cette commission permet de financer la solution et de couvrir les frais bancaires (fixés par notre partenaire de paiement).
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq15" aria-expanded="false">Combien ça coute ?</router-link>
					</h4>
					<div id="faq15" class="card-collapse collapse">
						<p>La solution peut être entièrement gratuite pour les restaurateurs mais il possible de choisir des options permettant des fonctionnalités supplémentaires (cf. page tarifs).
						<br>La solution est totalement gratuite pour les collaborateurs. Seul une commission sera prélevée sur les pourboires. 
						</p>
					</div>
				</div>
				<div class="page-title ">
					<h2 class="mt-0">Engagements et inscription</h2>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq16" aria-expanded="false">Suis-je engagé ?</router-link>
					</h4>
					<div id="faq16" class="card-collapse collapse">
						<p>Ni les restaurateurs ni les collaborateur sont engagés vis-à-vis d'epside (fournisseur de la solution). En effet, il est possible à n’importe quel moment de se désinscrire de la solution.
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq17" aria-expanded="false">Comment m’inscrire ?</router-link>
					</h4>
					<div id="faq17" class="card-collapse collapse">
						<p>Rendez vous sur la page inscription et choisissez 
							<ul class="policies">
								<li>Compte Etablissement,</li>
								<li>Compte Employé,</li>
								<li>Compte Donateur</li>
							</ul>
						</p>
					</div>
				</div>
				<div class="faq-card">
					<h4 class="faq-title">
						<router-link class="collapsed" data-bs-toggle="collapse" to="#faq18" aria-expanded="false">Comment se désinscrire ?</router-link>
					</h4>
					<div id="faq18" class="card-collapse collapse">
						<p>Le serveur pourra se désinscrire via l’application en cliquant sur le bouton dédié. En tant que client, si vous possédez un compte sur l’application, vous aurez la possibilité de vous désinscrire de la même manière à tout moment. En tant que responsable d'établissement, il vous suffira de nous contacter à l’adresse suivante : contact@epside.com.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
<!-- /FAQ Section -->
</template>