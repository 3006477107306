<template>
  <!-- Footer -->
  <footer class="footer">
    <NewsLetter />
    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="#"
                  ><img src="../../assets/img/footerlogo.svg" alt="logo"
                /></router-link>
              </div>
              <div class="footer-content">
                <p>Le pourboire digitalisé.</p>
              </div>
              <div class="social-icon">
                <ul>
                  <li>
                    <router-link to="#" target="_blank"
                      ><i class="fab fa-facebook-f"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="#" target="_blank"
                      ><i class="fab fa-twitter"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="#" target="_blank"
                      ><i class="fab fa-instagram"></i
                    ></router-link>
                  </li>
                  <li>
                    <router-link to="#" target="_blank"
                      ><i class="fab fa-linkedin-in"></i
                    ></router-link>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">A propos</h2>
              <ul>
                <li>
                  <router-link to="faq">Documentation</router-link>
                </li>
                <li>
                  <router-link to="/contact">Contactez nous</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Liens rapides</h2>
              <ul>
                <li>
                  <router-link to="howitworks">Comment ca marche?</router-link>
                </li>
                <li>
                  <router-link to="blog-grid">Notre Blog</router-link>
                </li>
                <li>
                  <router-link to="pricing">Tarifs</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <h2 class="footer-title">Communication</h2>
              <div class="footer-contact-info">
                <!--         <div class="footer-address">
                  <img src="../../assets/img/call-calling.svg" alt="Callus" />
                  <p>
                    <span>Téléphone</span> <br />
                    (+33) xx xx xx xx
                  </p>
                </div> -->
                <div class="footer-address">
                  <img src="../../assets/img/sms-tracking.svg" alt="Callus" />
                  <p>
                    <span>Email</span> <br />
                    contact@epside.fr
                  </p>
                </div>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6">
              <div class="copyright-text">
                <p class="mb-0">
                  {{ this.$t("general.copyright") }}
                  {{ new Date().getFullYear() }} -
                  {{ this.$t("general.company") }}
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="/privacy-policy"
                      >{{ this.$t("policy") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/faq">{{ this.$t("faq") }} </router-link>
                  </li>
                  <li>
                    <router-link to="/cgu">{{ this.$t("cgu") }}</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>

<script>
import NewsLetter from "./newsletter/Newsletter.vue";
export default {
  components: {
    NewsLetter,
  },
};
</script>