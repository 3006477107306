<template>
  <header class="header-two">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i
            ></a>
          </div>
          <ul class="main-nav">
            <li v-bind:class="{ active: currentPath == 'home' }">
              <router-link to="/">Accueil</router-link>
            </li>
            <li v-bind:class="{ active: currentPath == 'établissements' }">
              <router-link to="/establishments">Etablissements</router-link>
            </li>
            <li v-bind:class="{ active: currentPath == 'contact' }">
              <router-link to="contact">Contact</router-link>
            </li>
          </ul>
        </div>
        <ul class="nav header-navbar-rht">
          <li class="nav-item">
            <router-link
              v-if="!loggedIn"
              class="nav-link header-login register"
              to="/signup"
              >S'inscrire</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              v-if="!loggedIn"
              class="nav-link header-login"
              to="/login"
              >Connexion</router-link
            >
          </li>
          <li v-if="loggedIn" class="nav-item dropdown has-arrow logged-item">
            <a
              href="#"
              class="dropdown-toggle profile-userlink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img :src="profileImage" alt="avatar" />
              <span></span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link
                v-if="role <= 2"
                class="dropdown-item"
                to="/establishment"
                >Etablissement</router-link
              >
              <router-link class="dropdown-item" to="/profile"
                >Profil</router-link
              >
              <router-link class="dropdown-item" to="/logout"
                >Déconnexion</router-link
              >
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
const avatar_default = require(`@/assets/img/man-avatar-default.png`);
export default {
  data() {
    return {};
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    role() {
      let role = 10;
      if (this.loggedIn) {
        const user = JSON.parse(sessionStorage.getItem("userToken"));
        if (user !== null) role = user.role;
      }
      return role;
    },
    loggedIn() {
      let logged = false;
      if (this.$store) {
        logged =
          this.$store.state.firebaseAuth.status.loggedIn ||
          this.$store.state.auth.status.loggedIn;
      }
      return logged;
    },
    profileImage() {
      let image = avatar_default;
      if (this.loggedIn) {
        const user = JSON.parse(sessionStorage.getItem("userToken"));
        if (user !== null) image = user.photoURL;
      } else image = "";
      return image;
    },
  },
  mounted() {
    $("body").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function () {
      $("main-wrapper").toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      return false;
    });

    $(document).on("click", ".sidebar-overlay", function () {
      $("html").removeClass("menu-opened");
      $(this).removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });

    $(document).on("click", "#menu_close", function () {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $("main-wrapper").removeClass("slide-nav");
    });
    if ($(window).width() <= 991) {
      var Sidemenu = function () {
        this.$menuItem = $(".main-nav a");
      };

      function init() {
        var $this = Sidemenu;
        $(".main-nav a").on("click", function (e) {
          if ($(this).parent().hasClass("has-submenu")) {
            e.preventDefault();
          }
          if (!$(this).hasClass("submenu")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("submenu");
            $(this).next("ul").slideDown(350);
            $(this).addClass("submenu");
          } else if ($(this).hasClass("submenu")) {
            $(this).removeClass("submenu");
            $(this).next("ul").slideUp(350);
          }
        });
      }
      init();
    }
  },
};
</script>