export default {
  api: {
    backendUrl: 'https://api-tipsbackend-std-dot-tips-63e91.ew.r.appspot.com',
    BackendApiKey: '17f379ea-a60f-4abe-95df-3c26b5a4e31e'
  //  backendUrl: 'https://api-xxxx-std-dot-xxx.ew.r.appspot.com',
    //BackendApiKey: 'x'
  },
  pagination: {
    rowsPerPage: 10,
    rowsPerPageItems: [10, 25, 50, 100]
  },
  auth0: { 
    domain: "YOUR DOMAIN NAME",
    clientID: "YOUR clientID"
  },
  role: {
    superAdmin: 1,
    admin: 2,
    employee: 5,
    user: 10
  },
  defaultAuth: "firebase", // firebase or local
  stripeKey:"pk_test_51N1QImLTlDJh30LcrZQ5Pex5hEWYYIjLxjH5EVvxYhvhX8luyZ89XTuDCn2Xe6RCe7pCBHa0buih0p92PbOOYLuf00mUqLUfSR",
  firebase:{
    apiKey: "AIzaSyANq8XhraWVvFJFqf6bmG1Igds6wlCcIiI",
    authDomain: "tips-63e91.firebaseapp.com",
    projectId: "tips-63e91",
    storageBucket: "tips-63e91.appspot.com",
    messagingSenderId: "161396021233",
    appId: "1:161396021233:web:039695e28e5a4c6a555650",
    measurementId: "G-RC91KBL44W"
  }
}