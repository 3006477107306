import config from '@/config'
import {createRouter, createWebHistory} from 'vue-router';
import AuthGuard from './auth.guard';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/home/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/login/Login.vue')
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/logout/logout.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                next({ name: "login" });
            },
        },
    },
    {
        path: "/establishmentDetails",
        name: "establishmentDetails",
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/establishmentDetails/EstablishmentDetails.vue')
    },
    {
        path: "/tips-employee",
        name: "tipsEmployee",
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/tips-employee/TipsEmployee.vue')
    },
    {
        path: '/establishments',
        name: 'establishments',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/establishmentList/Index.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/about/Index.vue')
    },
    {
        path: '/tips-employee-final',
        name: 'tipsEmployeeFinal',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/tips-employee/TipsEmployeeFinal.vue')
    },
    {
        path: '/blog-details',
        name: 'blog-details',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/blog/blog-details/BlogDetails.vue')
    },
    {
        path: '/blog-grid',
        name: 'blog-grid',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/blog/blog-grid/BlogGrid.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/contact/Contact.vue')
    },
    {
        path: '/error-403',
        name: 'error-403',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/error/error403/Error403.vue')
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/error/error404/Error404.vue')
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/error/error500/Error500.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/faq/Faq.vue')
    },
    {
        path: '/forgot-password',
        name: 'Password',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/forgot-password/Password.vue')
    },
    {
        path: '/change-password/:token?',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/forgot-password/ChangePasswordLink.vue')
    },
    {
        path: '/howitworks',
        name: 'howitworks',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/howitworks/HowitWorks.vue')
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/pricing/PricingPlan.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/privacy-policy/PrivacyPolicy.vue')
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/signup/Signup.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/signup/Signup.vue')
    },
    {
         path: '/cgu',
        name: 'cgu',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/pages/cgu/index.vue')
    },
    // <--- administration screens --->
    {
        path: '/admin',
        name: 'admin-home',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/admin_pages/home/index.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin] }
    },
    {
        path: '/admin-blogList',
        name: 'admin-blogList',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/admin_pages/blogList/index.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin] }
    },
    {
        path: '/admin-blog-edit',
        name: 'admin-blog-edit',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/admin_pages/blog/index.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin] }
    },
    // <--- administration screens --->,
    // <--- etablishment screens --->,
    {
        path: "/establishment",
        name: "establishment",
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/establishment_pages/establishment/Establishment.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.admin] }
    },
    {
        path: '/employees',
        name: 'employees',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/establishment_pages/employeeList/EmployeeList.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.admin] }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/establishment_pages/dashboard/Dashboard.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.admin] }
    },
    {
        path: '/likes',
        name: 'likes',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/establishment_pages/likeList/LikeList.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.admin] }
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/establishment_pages/reviews/Review.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.admin] }
    },
    // <--- etablishment screens --->,
    // <--- employee screens --->,
    {
        path: '/my-dashboard',
        name: 'my-dashboard',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/employee_pages/dashboard/Dashboard.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.employee] }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/employee_pages/profile/Profile.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.admin, config.role.employee] }
    },
    {
        path: '/my-tips',
        name: 'my-tips  ',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/employee_pages/my-tips/MyTips.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.employee] }
    },
    {
        path: '/my-reviews',
        name: 'my-reviews',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/employee_pages/reviews/Review.vue'),
        meta: { loginRequired: true, roles: [config.role.superAdmin, config.role.employee] }
    },
    // <--- employee screens --->
]

export const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes,
}); 
router.beforeEach(AuthGuard)

