<template>
  <div class="">
    <ul class="dashborad-menus">
      <li :class="activeMenu === 'dashboard' ? 'active' : ''">
        <router-link to="my-dashboard">
          <i class="feather-grid"></i> <span>Tableau de bord</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'profile' ? 'active' : ''">
        <router-link to="profile">
          <i class="fa-solid fa-user"></i> <span>Profil</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'my-tips' ? 'active' : ''">
        <router-link to="my-tips">
          <i class="feather-list"></i> <span>Mes pourboires</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'reviews' ? 'active' : ''">
        <router-link to="my-reviews">
          <i class="fas fa-solid fa-star"></i> <span>Commentaires</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EmployeeMenu",
  props: {
    activeMenu: {
      type: String,
      required: true,
      default: "dashboard",
    },
  },
  data() {
    return {};
  },
};
</script>