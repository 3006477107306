// GLOBAL FILTERS
export default {
  getYesNoLabel(value) {
    return value === true ? 'Oui' : 'Non'
  },
  dateFormat(date) {
    const separator = '/'
    if (date !== undefined && date != null && date !== '' && date instanceof Date) {
      return ('0' + date.getDate()).slice(-2) + separator + ('0' + (date.getMonth() + 1)).slice(-2) + separator + date.getFullYear() + ' ' + ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2)
    } else if (date !== undefined && date != null && date !== '') {
      let newdate = new Date(date)
      return ('0' + newdate.getDate()).slice(-2) + separator + ('0' + (newdate.getMonth() + 1)).slice(-2) + separator + newdate.getFullYear() + ' ' + ('0' + (newdate.getHours())).slice(-2) + ':' + ('0' + (newdate.getMinutes())).slice(-2)
    } else {
      return undefined
    }
  },
  shortdateFormat(date) {
    const separator = '/'
    if (date !== undefined && date != null && date !== '' && date instanceof Date) {
      return ('0' + date.getDate()).slice(-2) + separator + ('0' + (date.getMonth() + 1)).slice(-2) + separator + date.getFullYear()
    } else if (date !== undefined && date != null && date !== '') {
      let newdate = new Date(date)
      return ('0' + newdate.getDate()).slice(-2) + separator + ('0' + (newdate.getMonth() + 1)).slice(-2) + separator + newdate.getFullYear()
    } else {
      return undefined
    }
  },// stripedHtml...
  stripedHtml(value) {
  if (value !== undefined && value !== null) {
    var stripedHtml = value.replace(/<\/li>/g, '. ').replace(/<[^>]+>/g, '')
    // replace space &nbsp;
    stripedHtml = stripedHtml.replace('&nbsp;', ' ')
    return stripedHtml
    }
  },
  addressFormat(addressData) {
    var addressString = ""
    if(addressData !== undefined) {
      if(addressData.street_number !== undefined) {
        addressString += addressData.street_number + " "
      }
      if(addressData.route !== undefined) {
        addressString += addressData.route + ","
      }
      if(addressData.postal_code !== undefined) {
        addressString += addressData.postal_code + " "
      }
      if(addressData.locality !== undefined) {
        addressString += addressData.locality + " "
      }
    }
    return addressString
  },
}