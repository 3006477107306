/* eslint-disable */
import Config from '../config'
import ApiQuery from './ApiQuery'
import { authHeader } from './auth-header'

export default {
  async getAll (objectName, filters, fields, pagination) {
    return new Promise((resolve, reject) => {
      let url = Config.api.backendUrl + '/api/v1/' + objectName

      url = ApiQuery.parseQueryParam(filters, url)
      if ( pagination !== null )
        url = ApiQuery.parsePagination(pagination, url)
      url = ApiQuery.parseFields(fields, url);
      fetch(url, {
        method: 'GET',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        })
      }).then(data => {
        data.json().then(jsonData => {
          if (data.ok) {
            resolve(jsonData.data)
          } else if (data.status === 404) {
            resolve(jsonData)
          } else {
            reject(jsonData)
          }
        }).catch(e => reject(new Error('error.dataNotProvided')))
      }).catch(e => reject(new Error('error.dataNotProvided')))
    })
  },
  async getById (objectName, id) {
    return new Promise ((resolve, reject) => {
      fetch(Config.api.backendUrl + '/api/v1/'+ objectName + '/' + id, {
        method: 'GET',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        })
      }).then (data => {
        data.json().then(jsonData => {
          if (data.ok) {
            resolve(jsonData.data)
          } else if (data.status === 404) {
            reject(new Error('error.dataNotProvided'))
          } else {
            reject(jsonData)
          }
        }).catch (e => { reject(e) })
      }).catch (e => { reject(e) })
    })
  },
  async create (objectName, requestBody) {
    return new Promise ((resolve, reject) => {
      let url = Config.api.backendUrl + '/api/v1/' + objectName
      fetch(url, {
        method: 'POST',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        }),
        body: JSON.stringify(requestBody)
      }).then(data => {
        if (data.ok) {
          resolve(data)
        } else {
          data.json().then(jsonData => {
            if(jsonData.length>0){
              reject(new Error(jsonData[0].path + ' ' + jsonData[0].message))
            } else reject(new Error(jsonData.message))})
        }
      }).catch(e => { reject(e) })
    })
  },
  async update (objectName, requestBody, id) {
    return new Promise ((resolve, reject) => {
      fetch(Config.api.backendUrl + '/api/v1/' + objectName + '/' + id, {
        method: 'PUT',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        }),
        body: JSON.stringify(requestBody)
      }).then (data => {
          if (data.ok) {
            resolve()
          } else if (data.status === 400) {
            data.json().then(jsonData => {
              if(jsonData.length>0){
                reject(new Error(jsonData[0].message))
              }
            })
          } else  reject(new Error('error.apiGlobalError'))
        }).catch (e => { reject(e) })
    }).catch(e => { reject(e) })
  },
  async count (objectName, filters) {
    return new Promise((resolve, reject) => {
      let url = Config.api.backendUrl + '/api/v1/'+ objectName + '/count'

      url = ApiQuery.parseQueryParam(filters, url)
      fetch(url, {
        method: 'GET',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        })
      }).then(data => {
        data.json().then(jsonData => {
          if (data.ok) {
            resolve(jsonData.data)
          } else if (data.status === 404) {
            resolve(jsonData.data)
          } else {
            reject(new Error('error.dataNotProvided'))
          }
        }).catch(e => reject(new Error('error.dataNotProvided')))
      }).catch(e => reject(new Error('error.dataNotProvided')))
    })
  },
  async delete (objectName, id) {
    return new Promise ((resolve, reject) => {
      fetch(Config.api.backendUrl + '/api/v1/'+ objectName + '/' + id, {
        method: 'DELETE',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        }),
      }).then (data => {
        resolve()
      }).catch (e => { reject(e) })
    })
  },
  async setStatus (objectName, status,id) {
    let s = {status: status}
    return new Promise ((resolve, reject) => {
      fetch(Config.api.backendUrl + '/api/v1/'+objectName+'/setStatus/' + id, {
        method: 'PUT',
        redirect: 'follow',
        headers: new Headers({
          'Content-Type': 'application/json',
          'x-api-key': Config.api.BackendApiKey,
          'authorization': authHeader()
        }),
        body: JSON.stringify(s)
      }).then (data => {
        if (data.ok) {
          resolve()
        } else if (data.status == 400) {
          data.json().then(jsonData => {
            if(jsonData.length>0){
              reject(new Error(jsonData[0].message))
            }
          })
        } else  reject(new Error('error.apiGlobalError'))
        }).catch (e => { reject(e) })
    }).catch(e => { reject(e) })
  }
}
