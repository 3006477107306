<template>
  <div class="">
    <ul class="dashborad-menus">
      <li :class="activeMenu === 'dashboard' ? 'active' :''">
        <router-link to="dashboard">
          <i class="feather-grid"></i> <span>Tableau de bord</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'establishment' ? 'active' :''">
        <router-link to="establishment">
          <i class="fa-solid fa-utensils"></i> <span>Etablissement</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'employees' ? 'active' :''">
        <router-link to="employees">
          <i class="fa-solid fa-users"></i> <span>Collaborateurs</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'likes' ? 'active' :''">
        <router-link to="likes">
          <i class="fas fa-solid fa-heart"></i> <span>Likés</span>
        </router-link>
      </li>
      <li :class="activeMenu === 'reviews' ? 'active' :''">
        <router-link to="reviews">
          <i class="fas fa-solid fa-star"></i> <span>Commentaires</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EstablishementMenu',
  props: {
    activeMenu: {
      type: String, 
			required: true, 
      default:"dashboard" }
  },
  data() {
    return {};
  }
};
</script>