<template>
    <div class="container">
      <div class="stay-tuned">
        <h3>Restons en contact</h3>
        <p>
            Inscrivez-vous à notre newletter et ne ratez plus nos dernières actualités .
        </p>
        <b-form>
          <div class="form-group">
            <div class="group-img">
              <i class="feather-mail"></i>
              <b-form-input
                type="text"
                class="form-control"
                v-model="email"
                placeholder="Saisissez votre adresse email"
              />
            </div>
          </div>
          <b-button variant="primary" type="submit" @click="subscribe()"> S'abonner</b-button>
        </b-form>
        <span v-if="v$.email.$error">{{ v$.email.$errors[0].$message }}</span>
      </div>
    </div>
</template>

<script>
import GenericServices from "@/modules/generic.services.js"
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import MessageModule from '@/modules/messageModule.js'
export default {  
  data() {
    return {
        v$: useVuelidate(),
        email: ''
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  },
  methods: {
    async subscribe() {
        this.v$.$validate() // checks all inputs
        if (!this.v$.$error) {
          try {
            await GenericServices.create('newsLetters',{email: this.email})
            MessageModule.setSuccess(this, 'News Letter', 'Vous êtes maintenant inscrit à la newsletter.')
          }
          catch(err) {MessageModule.setError(this, "Erreur", err.message);}
        } else {
          MessageModule.setWarning(this, 'News Letter', "L'email n'est pas conforme !")
        }
        
    }
  },
};
</script>