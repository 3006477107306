import AuthService from './../modules/auth.services'
import { getFirebaseBackend } from '@/modules/firebaseAuth.services'

const user = JSON.parse(sessionStorage.getItem('userToken'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const firebaseAuth = {
  namespaced: true,
  state: initialState,
  actions: {
    // eslint-disable-next-line no-unused-vars
    login({ commit, dispatch, getters }, user) {
      //   if (getters.loggedIn) return dispatch('validate')
      return getFirebaseBackend().loginUser(user.email, user.password, user.ipAddress).then(
        user => {
          return AuthService.loginWithFirebase(user).then(
            user => {
              sessionStorage.setItem('userToken', JSON.stringify(user))
              commit('loginSuccess', user);
              return Promise.resolve(user);
            },
            error => {
              commit('loginFailure');
              return Promise.reject(error);
            }
          );
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    loginWithGoogle({ commit}) {
      //   if (getters.loggedIn) return dispatch('validate')
      return getFirebaseBackend().loginUserWithGoogle().then(
        user => {
          return AuthService.loginWithFirebase(user).then(
            user => {
              sessionStorage.setItem('userToken', JSON.stringify(user))
              commit('loginSuccess', user);
              return Promise.resolve(user);
            },
            error => {
              commit('loginFailure');
              return Promise.reject(error);
            }
          );
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    loginWithApple({ commit}) {
      //   if (getters.loggedIn) return dispatch('validate')
      return getFirebaseBackend().loginUserWithApple().then(
        user => {
          return AuthService.loginWithFirebase(user).then(
            user => {
              sessionStorage.setItem('userToken', JSON.stringify(user))
              commit('loginSuccess', user);
              return Promise.resolve(user);
            },
            error => {
              commit('loginFailure');
              return Promise.reject(error);
            }
          );
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    loginWithFacebook({ commit}) {
      //   if (getters.loggedIn) return dispatch('validate')
      return getFirebaseBackend().loginUserWithFacebook().then(
        user => {
          return AuthService.loginWithFirebase(user).then(
            user => {
              sessionStorage.setItem('userToken', JSON.stringify(user))
              commit('loginSuccess', user);
              return Promise.resolve(user);
            },
            error => {
              commit('loginFailure');
              return Promise.reject(error);
            }
          );
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-unused-vars
        getFirebaseBackend().logout().then((response) => {
          sessionStorage.removeItem('userToken');
          sessionStorage.removeItem('userInfos');
          resolve(true);
          commit('logout');
        }).catch((error) => {
          reject(this._handleError(error));
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    register({ commit, dispatch, getters }, { username, email, password } = {}) {
      //  if (getters.loggedIn) return dispatch('validate')
      return getFirebaseBackend().registerUser(username, email, password).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        });
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};