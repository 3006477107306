import store from '../store'
export default (to, from, next) => {
  if (to.matched.some(record => record.meta.loginRequired)) {
    if (store && store.state.auth.status.loggedIn) {
        const user = JSON.parse(sessionStorage.getItem("userToken"));
        if (user !== null) {
            const roleArrayHierarchic = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles)
            if (roleArrayHierarchic.every(x => x.includes(user.role))) {
                next()
            } else {
                next('/error-403')
            } 
        } else {
                next('/error-403')
            } 
        
    } else {
        next('/error-403')
    }
  } else {
    next()
  }
}
