<template>
  <!-- Blog List -->
  <div class="bloglist-section blog-gridpage">
    <div v-if="blogList!==undefined && blogList.length>0" class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-4 d-lg-flex"
          v-for="item in blogList"
          :key="item.id"
        >
          <div class="blog grid-blog">
            <div class="blog-image">
              <router-link to="/blog-details"
                > <img
                  :src="
                    item.masterImage !== undefined &&
                    item.masterImage.url !== undefined
                      ? item.masterImage.url
                      : defaultImage
                  "
                  style="max-height: 180px" class="img-fluid"
                  alt="Blog Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <p class="blog-category">
                <a href="javascript:void(0)" v-for="tag in blogList.tags" :key="tag" ><span>{{tag}}</span></a>
              </p>
              <ul class="entry-meta meta-item">
                <li>
                  <div class="post-author">
                    <div class="post-author-img"> <img
                        :src="
							item.avatarImage !== undefined &&
							item.avatarImage.url !== undefined
								? item.avatarImage.url
								: defaultImage"
							alt="Author Image"
						/>
                    </div>
                    <a href="javascript:void(0)">
                      <span> {{ item.userName !== undefined ? item.userName : "Admin" }} </span></a
                    >
                  </div>
                </li>
                <li class="date-icon">
                  <i class="fa-solid fa-calendar-days"></i> {{ dateFormat(item.updatedAt) }}
                </li>
              </ul>
              <h3 class="blog-title">
                <router-link to="blog-details">{{ item.title }}</router-link>
              </h3>
              <p class="blog-description">{{ item.content }}</p>
              <div class="viewlink">
                <router-link to="blog-details"
                  >Détails<i class="feather-arrow-right"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Pagination-->
      <div class="blog-pagination">
        <nav>
          <div class="row">
            <div class="col-12">
              <b-pagination
                align="center"
                :total-rows="pagination.totalItems"
                :per-page="pagination.rowsPerPage"
                v-model="currentPage"
                @input="getDatas"
                prev-text="Précédent"
                next-text="Suivant"
                class="pagelink page-item"
              />
            </div>
          </div>
        </nav>
      </div>
      <!--/Pagination-->
    </div>
    <div v-else align="center">
      <noData :title="$t('noBlogListFound')"></noData>
    </div>
  </div>
  <!-- /Blog List -->
</template>

<script>
import GenericServices from "@/modules/generic.services.js"
const defaultImage = require(`@/assets/img/establishment-default.jpg`)
const avatarImage = require(`@/assets/img/man-avatar-default.png`)
import filters from "@/modules/filters.js"

export default {
  components: {
  },
  data() {
    return {
      avatarImage: avatarImage,
      defaultImage: defaultImage,
      blogList: [],
      pagination: {
        page: 1,
        rowsPerPage: 9,
        rowsPerPageItems: [9],
        totalItems: 0,
        sortBy: "createdAt",
        descending: false,
      },
    };
  },
  async mounted() {
    let result = await GenericServices.getAll(
      "blogs/",
      {},
      undefined,
      this.pagination
    );
    this.blogList = result.entries;
  },
  methods: {
    dateFormat(date) {
      return filters.dateFormat(date);
    },
  }
};
</script>