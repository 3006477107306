<template>
    <div v-if="loading">
        <FingerprintSpinner
          :animation-duration="1500"
          :size="64"
          color="#C10037"
        />
    </div>
</template>
  
  <script>
  import { FingerprintSpinner } from 'epic-spinners'
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Loading',
    props: {
      //loading
      loading: { default: true, type: Boolean }
    },
    data () {
      return {
      }
    },
    components: {
      FingerprintSpinner
    }
  }
  </script>
  