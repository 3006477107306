export default {
  async showMessage (title = '', message = '', type = 'success')  {
      const toast = window.Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 3000,
      });
      toast.fire({
          icon: type,
          title: title,
          text: message,
          padding: '10px 20px',
      });
  },
  async setSuccess (context, message, description) {
    this.showMessage(message, description, 'success')
  },
  async setWarning (context, message, description) {
    this.showMessage(message, description, 'warning')
  },
  async setError (context, message, description) {
    this.showMessage(message, description, 'error')
  },
  async setWarningTranslate (context, message, description) {
    this.showMessage(context.$t(message), context.$t(description), 'warning')
  },
  async setSuccessTranslate (context, message, description) {
    this.showMessage(context.$t(message), context.$t(description), 'success')
  },
  async setErrorTranslate (context, message, description) {
    this.showMessage(context.$t(message), context.$t(description), 'error')
  }
}
