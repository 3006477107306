/* eslint-disable vue/multi-word-component-names */
import config from "@/config.js"
import { createApp } from 'vue';
import { router } from './router';
import errorHandler from './errorHandler';
import App from "./App.vue";
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import VueApexCharts from "vue3-apexcharts";
import VueSelect from 'vue3-select2-component'
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueEasyLightbox from "vue-easy-lightbox";
import AOS from 'aos'
import VueCarousel from 'vue-carousel';

// Multi Language
import en from './locales/en.json'
import fr from './locales/fr.json'
import { createI18n } from "vue-i18n"
import { getCurrentLanguage } from './modules/utils'

// Header Components
import Navbar from './components/nav/navbar.vue'

// Page Components
import Header from './views/layouts/header.vue'
import Foot from './views/pages/Footer.vue'
import Scroll from './views/pages/Scroll.vue'
import NoData from './components/common/NoData.vue'
import Loading from './components/common/Loading.vue'
import PeriodDropDown from './components/form/PeriodDropDown.vue'
import EmployeeMenu from './views/employee_pages/common/EmployeeMenu.vue'
import EstablishmentMenu from './views/establishment_pages/common/EstablishmentMenu.vue'
import BlogGridList from './views/pages/blog/blog-grid/BlogGridList.vue'
import ContactInfo from './views/pages/contact/ContactInformation.vue'
import FaqContent from './views/pages/faq/FaqContent.vue'
import ForgotPassword from './views/pages/forgot-password/ForgotPassword.vue'
import HowWorks from './views/pages/howitworks/Works.vue'
import LoginForm from './views/pages/login/LoginForm.vue'
import PrivacyPolicy from './views/pages/privacy-policy/PrivacyTerms.vue'
import SignupLogin from './views/pages/signup/SignupLogin.vue'

// Breadcrumbs 
import BreadCrumb from './components/breadcrumb/Component.vue'

// plugins
import 'aos/dist/aos.css'
//import './assets/js/backToTop.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/feather.css';
import './assets/css/style.css';
import store from './store';
import { initFirebaseBackend } from './modules/firebaseAuth.services'

if (config.defaultAuth === "firebase") {
    initFirebaseBackend(config.firebase)
  }
//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// multi language
const messages = { en: en, fr: fr }
const locale = getCurrentLanguage()
const i18n = new createI18n({
  locale: locale,
  fallbackLocale: 'fr',
  messages
})

const app = createApp(App)
app.component('layouts',Header)
app.component('navbar',Navbar)
EmployeeMenu
app.component('foot',Foot)
app.component('scroll',Scroll)
app.component('noData',NoData)
app.component('loading',Loading)
app.component('periodDropDown',PeriodDropDown)
app.component('EmployeeMenu',EmployeeMenu)
app.component('EstablishmentMenu',EstablishmentMenu)
app.component('bloggridlist',BlogGridList)
app.component('contactinformation',ContactInfo)
app.component('faqcontent',FaqContent)
app.component('forgotpassword',ForgotPassword)
app.component('howworks',HowWorks)
app.component('loginform',LoginForm)
app.component('privacypolicy',PrivacyPolicy)
app.component('signuplogin',SignupLogin)

// Breadcrumb
app.component('breadcrumb',BreadCrumb)

app.component('vue-select', VueSelect)
.use(store)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(AOS.init())
app.use(VueEasyLightbox);
app.use(CoolLightBox);
app.use(VueApexCharts);
app.use(VueCarousel);

app.use(i18n)
app.use(router).mount('#app');

app.config.errorHandler = errorHandler